:root {
    --bs-light-rgb: 243,243,243;
    --bs-danger-rgb: 236, 104, 56;
    --bs-border-radius: 0.8rem;
    --bs-border-radius-lg: var(--bs-border-radius);
    --bs-border-width: 0;

    --online-text-rgb: 25, 25, 25;
    --online-primary: #6288A6;
    --online-secondary: #66AEEE;
    --online-success: #47F28B;
    --online-danger: #FE6869;
    --online-warning: #FFBD5B;
    --online-lighter: #D7D8D9;
    --online-light: #909598;
    --online-dark: #32373c;
    --online-light-dark: #32373c;
    --online-light-list-separator: #2664a11f;
    --online-card-list-separator: #6987A333;
    --online-card-hover: #67AFEE1A;
    --online-form-error: #ec6838;
    --online-payplan-success: #4cae4c;
    --online-profilemenu-buttoncolor: #e7f0f7;
    --online-not-selected: #B5B5B5;
}

@font-face {
    font-family: 'Labil';
    src:
            url(../fonts/LabilGrotesk-50Regular.woff2) format('woff2'),
            url(../fonts/LabilGrotesk-50Regular.woff) format('woff');
}

@font-face {
    font-family: 'Labil';
    font-style: italic;
    src:
            url(../fonts/LabilGrotesk-55RegularItalic.woff2) format('woff2'),
            url(../fonts/LabilGrotesk-55RegularItalic.woff) format('woff');
}

@font-face {
    font-family: 'Labil';
    font-weight: bold;
    src:
            url(../fonts/LabilGrotesk-70Bold.woff2) format('woff2'),
            url(../fonts/LabilGrotesk-70Bold.woff) format('woff');
}

body {
    font-family: "Labil", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Oxygen, Ubuntu, "Droid Sans", "Helvetica Neue";
    font-size: 16px;
    color: rgb(var(--online-text-rgb));
    transition: padding 0.4s ease-in-out;
    background-color: rgb(var(--bs-light-rgb));
}

/* General helpers */
.offcanvas-backdrop {
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 1 !important;
}

hr {
    display: block;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
    border-color: var(--online-card-list-separator);
}

hr.hide {
    border-width: 1px;
    border-color: transparent;
}

/* Titles and typography overrides */
h1, h2, h3, h4, h5, h6 {
    font-size: 2.5rem;
    font-weight: bold;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.75rem;
}

h4 {
    font-size: 1.45rem;
}

h5 {
    font-size: 1.25rem;
}

h6 {
    font-size: 1rem;
}

.fs-6 {
    font-size: 0.9rem !important;
}

#main-container {
    min-height: 100vh;
}

#main-container.login-container {
    min-height: 100vh;
}

#dashboard {
    padding-bottom: 70px !important;
}

#job-page {
    padding: 24px 24px 45px;
}

.text-primary {
    color: var(--online-primary) !important;
}

.text-success {
    color: var(--online-success) !important;
}

.text-danger {
    color: var(--online-danger) !important;
}

.text-warning {
    color: var(--online-warning) !important;
}

.text-lighter {
    color: var(--online-lighter) !important;
}

.text-dark {
    color: var(--online-dark) !important;
}

a, a:visited {
    text-decoration: none;
    color: var(--online-secondary);
}

a:hover {
    color: var(--online-primary);
}

/* ToggleLoading */
#loading {
    background-color: rgb(255 255 255 / 30%);
    backdrop-filter: blur(5px);
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    text-align: center;
    justify-content: center;
}

#loading.visible {
    display: flex;
    align-items: center;
    justify-content: center;
}

#loading svg {
    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    width: 50px;
    margin: 0;
    display:inline-block;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

#loading .content {
    color: rgb(var(--online-text-rgb));
    margin-top: 50px;
    font-size: 1.25em;
}

/* Login */
.login {
    padding-right: 10%;
    padding-left: 10%;
    padding-bottom: 10%;
    background: url(/public/img/Light-fade-right.png);
    background-size: cover;
    background-position: top;
    background-repeat: repeat;
    overflow: auto;
}

.login-header {
    margin-top: 30px;
    min-height: auto;
    text-align: end;
    height: 25%;
}

.login-text {
    color: var(--bs-white);
}

.login-language.btn,
.login-language.btn:active,
.login-language.btn:focus,
.login-language.btn:hover {
    padding: 10px 15px;
    font-size: 0.9rem;
    text-decoration: none;
    color: var(--bs-white);
    background-color: transparent;
}

.login-language span.option-desc {
    color: var(--online-not-selected);
}

.login-language.selected span.option-desc {
    color: var(--bs-white);
}

.login-language span.option-desc:hover {
    color: var(--online-primary);
}

.login-language span.option-icon {
    display: none;
}

.login-language .dropdown-menu {
    background: transparent;
    text-align: right;
    width: 100%;
}

.login-language .dropdown-item:hover {
    color: var(--online-primary);
}

.login .btn.login-button {
    border-radius: var(--bs-border-radius);
    padding: 0px;
    border: 1px solid white;
    background-color: transparent;
    display: flex;
    color: #fff;
}

.login-button-icon {
    width: 60px;
    font-size: 18px;
    padding-top: 14px;
}

.login-button-desc {
    border-left: 0.5px solid white;
    padding: 15px;
    width: 100%;
}

.login-button-desc:hover,
.login-button-icon:hover~.login-button-desc,
.login .btn.login-button:hover {
    border-color: var(--online-primary);
    color: var(--online-primary);
}

/* Sidemenu */
.sidemenu {
    margin: 1.8rem 1.8rem 1.8rem 2.5rem;
}

#sidemenu {
    min-height: 100vh;
    min-width: 300px;
    padding-top: 1rem;
    z-index: 100;
}

.sidemenu .nav-item,
.sidemenu .nav-item a {
    font-size: 19px;
    font-weight: normal;
    color: var(--bs-black);
    text-decoration: none;
}

.sidemenu .active {
    font-weight: bold !important;
}

.sidemenu .nav-item a:hover,
.sidemenu .nav-item-small a:hover {
    color: var(--online-primary);
}

.sidemenu .nav-item i,
.sidemenu .nav-item svg,
.sidemenu .nav-item-small svg{
    margin-right: 6px;
}

.sidemenu .nav-item-small,
.sidemenu .nav-item-small a {
    font-size: 1rem;
    font-weight: normal;
    color: var(--bs-black);
    text-decoration: none;
}

.sidemenutoggle {
    font-size: 1.8rem;
    color: rgb(var(--online-text-rgb));
    position: relative;
}

.sidemenuclose {
    position: absolute;
    top: 2rem;
    right: 2rem;
    font-size: 1.8rem;
    color: rgb(var(--online-text-rgb));
    background: transparent;
    border: 0;
}

.sidemenu-border {
    border-top: 1px solid var(--online-lighter);
    top: calc(100vh - 150px);
    position: -webkit-sticky;
    position: sticky;
}

.icon-need-actions {
    position: absolute;
    top: 5px;
    right: 3px;
    width: 12px;
    height: 12px;
    background-color: var(--online-danger);
    border-radius: 50%;
    border: 2px solid rgb(var(--bs-light-rgb));
}


.actionlist-button .message-center-new-messages {
    font-size: 12px;
    margin-left: 5px;
}

.notification-badge {
    background-color: var(--online-danger);
    color: var(--bs-white);
    border-radius: 50%;
    text-align: center;
    position: absolute;
    top: 0;
    right: -30px;
    width: 24px;
    height: 24px;
    padding: 5px 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.navbar-brand {
    margin-top: 2rem;
    margin-left: 2.5rem;
}

/* Top Menu */
.top-menu-position {
    position: relative;
    left: 300px;
    width: calc(100% - 300px);
}

.top-menu  {
    padding-left: 25px;
    padding-right: 20px;
}

.top-menu button {
    border: 0;
    background-color: transparent;
    text-align: center !important;
    color: rgb(var(--online-text-rgb)) !important;
}

.top-menu .topbar-brand {
    padding-top: 8px;
    padding-left: 0;
}

/* Profile menu */
.profilemenu {
    position: absolute;
    top: 20px;
    padding: 10px 10px 13px 10px;
    background: white;
    border-radius: 50%;
    text-align: center !important;
    width: 3rem;
    height: 3rem;
    font-size: 1.1rem;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
    z-index: 1;
    display: flex !important;
    justify-content: center !important;
}

.profilemenu-open {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100vh;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255) 85%);
    backdrop-filter: blur(2px);
    opacity: 1 !important;
    z-index: 199;
}

.profile span.option-icon {
    padding: 15px;
    background: var(--online-profilemenu-buttoncolor);
    border-radius: 50%;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
    text-align: center !important;
    color: rgb(var(--online-text-rgb)) !important;
}

.profilemenu-background {
    position: absolute;
    top: -0.5rem;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: right;
    font-size: 1.1rem;
    z-index: 200;
}

.profilemenu-header {
    display: flex;
    justify-content: end;
    padding-top: 30px;
    margin-bottom: 20px;
}

.profilemenu-option span.option-icon,
.profilemenu-contents .submenu span.option-icon {
    display: inline-block;
    background: var(--online-profilemenu-buttoncolor) !important;
    border-radius: 50%;
    text-align: center;
    margin-bottom: 5px;
    padding-top: 11px;
}

.profilemenu-option span.option-icon {
    width: 3rem;
    height: 3rem;
    padding: 10px;
    padding-top: 11px;
}

.profilemenu-option span.option-desc {
    padding-right: 12px;
    font-size: 1rem;
}

.profilemenu-contents .submenu span.option-icon {
    width: 2.3rem;
    height: 2.3rem;
    font-size: 0.6rem;
}

.profilemenu-contents .submenu span.option-desc {
    font-size: 0.9rem;
    padding-right: 8px;
    padding-top: 6px;
}

.profilemenu-contents .submenu {
    padding-right: 11px;
}

/* Form controls */
.form-control {
    border-radius: (--bs-border-radius);
    border: 0;
    padding: 0.9rem;
}

select.form-control {
    border: 1px solid var(--online-lighter);
    -webkit-box-shadow:inset 1px 1px 3px #00000014;
    -moz-box-shadow:inset 1px 1px 3px #00000014;
    box-shadow:inset 1px 1px 3px #00000014;
}

textarea.form-control {
    border: 1px solid var(--online-lighter);
    -webkit-box-shadow:inset 1px 1px 3px #00000014;
    -moz-box-shadow:inset 1px 1px 3px #00000014;
    box-shadow:inset 1px 1px 3px #00000014;
}

.is-invalid {
    border: 1px solid var(--online-danger);
}

input.form-control {
    border: 1px solid var(--online-lighter);
    -webkit-box-shadow:inset 1px 1px 3px #00000014;
    -moz-box-shadow:inset 1px 1px 3px #00000014;
    box-shadow:inset 1px 1px 3px #00000014;
}

.has-search input.form-control {
    border: 0;
    box-shadow: none;
}

.has-search .form-control-search {
    pointer-events: none;
}

.input-group .btn {
    background-color: white;
    color: var(--online-primary);
    padding: 10px 20px;
}

.input-group-text {
    background-color: white;
}

.input-group .invalid-feedback {
    width: 1px;
}

.form-group .invalid-feedback {
    color: var(--online-form-error);
    font-size: 0.8em;
    margin: 8px 0;
    display: block;
}

form > * input[type="radio"],
form > * input[type="checkbox"] {
    border-color: var(--online-dark);
    box-shadow: 0 0 0 1px var(--online-dark);
}

form.error > * input[type="radio"],
form.error > * input[type="checkbox"] {
    border-color: var(--online-danger);
    box-shadow: 0 0 0 1px var(--online-danger);
}

form.error > * label {
    color: var(--online-form-error);
}

.message-center-form .form-control:focus::placeholder {
    color: var(--online-light) !important;
}


/* Number inputs */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* Status badges */
.badge {
    border-radius: 0.4rem;
}

.bg-success,
.status-paid {
    background-color: var(--online-success) !important;
    color: rgb(var(--online-text-rgb)) !important;
}

.bg-secondary,
.status-invoice {
    background-color: var(--online-secondary) !important;
    color: white !important;
}

.bg-warning,
.status-late {
    background-color: var(--online-warning) !important;
    color: rgb(var(--online-text-rgb)) !important;
}

.bg-danger,
.status-reminder {
    background-color: var(--online-danger) !important;
    color: white !important;
}

.status-collection {
    background-color: var(--online-dark);
    color: white;
}

/* Cards */
.card {
    border: 0;
    border-radius: 20px;
    padding: 15px;
}

.card.messagecard {
    background-color: transparent;
    padding: 0;
}

.card.messagecard.chosen {
    background-color: white;
    border: none;
}

.card.messagecard .card-body .message-center-new-messages {
    float: right;
    font-size: 12px;
    margin-top: 6px;
}

.card.invoice {
    border: 0;
    border-radius: 20px;
    padding: 5px;
    margin-bottom: 20px;
    border-right: 39px solid transparent;
}

.invoice-card-icon {
    font-family: "Font Awesome 6 Pro", sans-serif;
    font-weight: 400;
    position: absolute;
    top: calc(50% - 10px);
    right: -23px;
}

.card.invoice.warning {
    border-right: 39px solid var(--online-warning);
}

.invoice-card-icon.warning {
    right: -23px;
    color: white;
}

.card.invoice > .card-body {
    padding: 16px 0 16px 16px;
}

.card.invoice-details {
    margin-bottom: 20px;
}

.card.invoice-details.no-rounding-top {
    border: 0;
    border-radius: 0 0 20px 20px;
    margin-bottom: 20px;
}

.card.invoice-details.no-rounding-bottom {
    border: 0;
    border-radius: 20px 20px 0 0;
    margin-bottom: 5px;
}

.card.invoice-details.payment-details,
.card.invoice-details.job-shortsummary {
    box-shadow: none;
}

.card.invoice-details.job-shortsummary > .card-body {
    padding-bottom: 0;
    padding-left: 10px;
}

.card.invoice h5 {
    margin-bottom: 0;
}

.card.notification {
    border-right: 39px solid var(--online-dark);
}

.card.notification:hover {
    cursor: pointer;
}

.card.notification.primary {
    border-right: 39px solid var(--online-primary);
}

.card.notification.secondary {
    border-right: 39px solid var(--online-secondary);
}

.card.notification.success {
    border-right: 39px solid var(--online-success);
}

.card.notification.danger {
    border-right: 39px solid var(--online-danger);
}

.card.notification.warning {
    border-right: 39px solid var(--online-warning);
}

.card.notification.light {
    border-right: 39px solid var(--online-light);
}

.notification-icon {
    font-family: "Font Awesome 6 Pro", sans-serif;
    font-weight: 400;
    position: absolute;
    top: calc(50% - 10px);
    right: -25px;
    color: white;
}

.card-borderless {
    border: 0;
    box-shadow: none;
    margin-bottom: 50px;
}

.card-header {
    background: transparent;
}

.read-more.closed {
    max-height: 100px;
    overflow: hidden;
    transition: max-height 1s cubic-bezier(0, 1, 0, 1);
}

.read-more.open {
    overflow: auto;
    max-height: 1000px;
    transition: all 1s ease-in-out;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.read-more.bottom-click-area {
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0;
    font-size: 12px;
    color: #1f69b2;
    z-index: 100;
}

.read-more.bottom-gradient {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255) 60%);
    left: 0;
    border-radius: 20px;
}

.read-more.bottom-gradient.off {
    height: 50px;
}

.read-more.bottom-gradient.on {
    height: 109px;
}

/* Message Center */
.message {
    border-radius: 20px;
    padding: 15px;
    margin-bottom: 20px;
}

.message.customer {
    background-color: rgb(var(--bs-light-rgb));
    margin-left: 50px;
}

.message.support {
    background-color: var(--online-profilemenu-buttoncolor);
    margin-right: 50px;
}

.message-container {
    position: relative;
    height: calc(100vh - 250px);
    overflow: hidden;
}

.scrollable-content-message,
.scrollable-content {
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    border-radius: 20px;
}

.scrollable-content-message .fixed-element-bottom {
    padding: 10px;
    margin-bottom: 10px;
}

.scrollable-content-message .scroll-message-ref {
    height: 75px;
}

.scrollable-content-message .fixed-element-bottom .input-group {
    display: inline-flex;
    width: 100%;
    margin-right: 10px;
    margin-bottom: 0px !important;
}

.scrollable-content-message .fixed-element-bottom .icon-button {
    background: none;
    border: none;
    padding: .375rem .75rem !important;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.scrollable-content-message .fixed-element-bottom .icon-button .icon {
    font-size: 1; /* Adjust the size as needed */
    color: var(--online-primary);
}

.fixed-element-bottom {
    position: sticky;
    bottom: 0px;
    background-color: white;
    padding: 32px 0;
}

.form-modal-sub-title {
    font-size: 1rem;
    font-weight: 500;
}

#message-center-form .input-group .input-group-text{
    border: 1px solid var(--online-lighter);
    border-radius: 10px 0px 0px 10px;
    border-right: none;
}

#message-center-form textarea {
    border-radius: 0px 10px 10px 0px;
}

#message-center-form input.form-control,
#message-center-form textarea,
#message-center-form select {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

.message-center-form .input-group .input-group-text {
   border: 1px solid var(--online-lighter);
   border-radius: 10px 10px 10px 10px;
}

.message-center-form textarea {
   border-radius: 0px 10px 10px 0px;
}

.message-center-form input.form-control,
.message-center-form textarea,
.message-center-form select {
   box-shadow: none;
   -webkit-box-shadow: none;
   -moz-box-shadow: none;
}

.attachment-icon {
    font-size: 1.5rem;
    color: var(--online-primary);
}

.author-name {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-size: 0.8em;
}

.commenting-not-available {
    text-align: center;
    padding-bottom: 24px;
}

/* Payment modal */
.payment-method {
    box-shadow: 0px 1px 4px rgba(0,0,0,0.15);
    text-align: center;
    transition: transform .2s; /* Animation */
}

.payment-method:hover, .payment-method:focus {
    box-shadow: 0px 1px 4px rgba(0,0,0,0.2);
    transform: scale(1.1);
    border: 1px solid var(--online-secondary);
}

.payment-method img {
    max-width: 100%;
    display: block;
    margin: 1rem auto;
}

.payment-method .tinkstyle {
    max-width: 60%;
    margin-top: 2.4rem;
    margin-bottom: 2.8rem;
}

/* Status infos */
.card.status {
    position: relative;
    border-bottom: 8px solid var(--online-light);
}

.card.status .badge {
    position: absolute;
    top: 20px;
    right: 20px;
}

.card.status.paid {
    border-bottom: 8px solid var(--online-success);
}

.card.status.invoicing {
    border-bottom: 8px solid var(--online-secondary);
}

.card.status.late {
    border-bottom: 8px solid var(--online-warning);
}

.card.status.reminder {
    border-bottom: 8px solid var(--online-danger);
}

.card.status.collection {
    border-bottom: 8px solid var(--online-dark);
}

/* Alerts */
.alert {
    border-left: 20px solid var(--online-dark);
    background: white;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.15);
}

.alert.primary {
    border-left: 20px solid var(--online-primary);
}

.alert.secondary {
    border-left: 20px solid var(--online-secondary);
}

.alert.success {
    border-left: 20px solid var(--online-success);
}

.alert.danger {
    border-left: 20px solid var(--online-danger);
}

.alert.warning {
    border-left: 20px solid var(--online-warning);
}

.alert.light {
    border-left: 20px solid var(--online-light);
}

/* Tabs */
.online-tabs .nav-link {
    padding-top: 0;
}

.online-tabs .nav-item a {
    font-size: 1.6rem;
    color: rgb(var(--online-text-rgb));
    border-bottom: 8px solid var(--online-lighter);
    transition: all 0.3s ease-in-out;
}

.online-tabs .nav-item a:hover {
    color: var(--online-primary);
}

.online-tabs .nav-item .active {
    font-weight: bold;
    color: var(--online-primary);
    border-bottom: 8px solid var(--online-primary)
}

.retrieve-invoice .online-tabs .nav-item a {
    font-size: 1rem;
}

.subjobs .nav-item .nav-link.active {
    font-weight: bold;
    color: white;
    background-color: var(--online-primary);
    border: 1px solid var(--online-primary);
}

.subjobs .nav-item .nav-link:hover {
    background-color: var(--online-primary);
    border: 1px solid var(--online-primary);
    color: white;
}

.subjobs .nav-item .nav-link {
    border: 1px solid var(--online-lighter);
    color: var(--online-text-rgb);
    margin: 6px 6px 3px 0;
    padding: 6px 20px;
}

/* Icons */
.icon {
   font-weight: lighter;
}

.icon-primary {
    color: var(--online-primary);
}

.icon-hover-danger {
    color: var(--online-dark);
}

.icon-hover-danger:hover {
    color: var(--online-danger);
}

.icon.clickable {
    cursor: pointer;
}

/* Buttons */
.btn {
    border-radius: var(--bs-border-radius);
    padding: 10px 30px;
}

.btn-primary {
    background-color: var(--online-primary);
    border-color: var(--online-primary);
}

.btn-primary:hover {
    background-color: var(--online-primary);
    border-color: var(--online-primary);
    filter: brightness(135%);
}

.btn-primary:active,
.btn-primary:focus {
    background-color: var(--online-primary);
    border-color: var(--online-primary);
    filter: brightness(90%);
}

.btn-secondary {
    background-color: white;
    color: var(--online-primary);
    border: 1px solid var(--online-primary);
}

.btn-secondary:hover {
    background-color: var(--online-primary);
    border-color: var(--online-primary);
    color: white;
}

.btn-secondary:active,
.btn-secondary:focus {
    background-color: white;
    color: var(--online-primary);
    filter: brightness(90%);
}

.btn-success {
    background-color: white;
    color: rgb(var(--online-text-rgb));
    border-color: var(--online-success);
}

.btn-success:hover {
    background-color: var(--online-success);
    border-color: var(--online-success);
    color: white;
}

.btn-success:active,
.btn-success:focus {
    background-color: white;
    color: var(--online-success);
    filter: brightness(90%);
}

.btn-danger {
    background-color: white;
    color: rgb(var(--online-text-rgb));
    border-color: var(--online-danger);
}

.btn-danger:hover {
    background-color: var(--online-danger);
    border-color: var(--online-danger);
    color: white;
}

.btn-danger:active,
.btn-danger:focus {
    background-color: white;
    color: var(--online-danger);
    filter: brightness(90%);
}

.btn-warning {
    background-color: white;
    color: rgb(var(--online-text-rgb));
    border-color: var(--online-warning);
}

.btn-warning:hover {
    background-color: var(--online-warning);
    border-color: var(--online-warning);
    color: white;
}

.btn-warning:active,
.btn-warning:focus {
    background-color: white;
    color: var(--online-warning);
    filter: brightness(90%);
}

.btn:disabled,
.btn[disabled]{
    border: 1px solid var(--online-light);
    background-color: white;
    color: var(--online-light);
    opacity: 1;
}

.btn-tertiary {
    color: var(--online-primary);
    text-decoration: underline;
    border: 0;
}

.btn-tertiary:active,
.btn-tertiary:focus {
    color: var(--online-text-rgb);
}

.btn-tertiary-sidemenu {
    color: var(--bs-black);
    text-decoration: none;
    font-size: 1rem;
}
.btn-tertiary-sidemenu:hover {
    color: var(--online-primary);
}

.btn.invoice-card {
    padding: 0;
    width: 100%;
    text-align: start;
}

.btn.messagecard {
    text-align: start;
    padding: 0;
    border-radius: 20px;
}

.btn.messagecard:hover {
    background-color: var(--online-card-hover);
}

.btn-link-style {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: var(--online-secondary);
}

.invoice-messagecenter-messages .scrollable-content {
    overflow: initial;
}

.invoice-messagecenter-messages .btn.messagecard {
    margin: 0px -10px;
}

.btn.listbutton-disabled,
.list-unstyled > * .btn.btn-tertiary:not(#card-serving-button) {
    color: rgb(var(--online-text-rgb));
    font-weight: bold;
    text-decoration: none;
}

.btn.listbutton-disabled-sidemenu {
    font-weight: normal;
    background-color: white;
    color: var(--online-light) !important;
    opacity: 1;
}

.btn.listbutton-disabled,
.btn.listbutton-disabled > * svg {
    background-color: white;
    color: var(--online-light) !important;
    opacity: 1;
}

.btn.actionlist-button {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    text-align: start;
}

.btn.extend-duedate {
    border-radius: 4px;
    padding: 4px 10px;
    font-weight: 500;
    font-size: 0.75rem;
}

.btn.extend-duedate svg {
    float: right;
    margin-top: 3px;
}

.btn.primary-disabled,
.btn.secondary-disabled {
    border: 1px solid var(--online-light);
    background-color: white;
    color: var(--online-light);
    opacity: 1;
    filter: none;
}

.btn.primary-disabled:hover,
.btn.primary-disabled:active {
    background-color: var(--online-light);
    border-color: var(--online-light);
    color: var(--bs-btn-active-color);
}

.btn.browse-job,
.btn.browse-job:active {
    background-color: white;
    color: var(--online-dark);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 15px;
    height: 44px;
    font-size: 0.9rem;
}

.btn.browse-job:hover {
    color: var(--online-primary);
}

.btn.browse-job.browse-disabled,
.btn.browse-job.browse-disabled:hover,
.btn.browse-job.browse-disabled:active,
.btn.browse-job.browse-disabled:focus {
    background-color: white;
    color: var(--online-light);
    opacity: 1;
    cursor: default;
}

.btn.browse-job.previous {
    border-radius: var(--bs-border-radius) 0 0 var(--bs-border-radius);
    padding-right: 2px;
}

.btn.browse-job.next {
    border-radius: 0 var(--bs-border-radius) var(--bs-border-radius) 0;
    padding-left: 2px;
}

.browse > span {
    background: white;
    height: 44px;
    font-size: 0.9rem;
    align-content: center;
}

.browse-disabled {
    color: var(--online-light);
}

.list-unstyled > * .actionlist-button > svg {
    color: var(--online-primary);
    margin-right: 6px;
}

.list-unstyled > * .btn.listbutton-disabled > svg  {
    color: var(--online-light);
}

.feedback-btn-circle {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 14px;
    white-space: nowrap;
    border-radius: 20px;
    margin: 4px 3px;
    background-color: #FFF;
    color: var(--online-primary);
    border: 1px solid var(--online-primary);
}

.selected-score {
    background-color: var(--online-primary);
    color: #FFF;
}

.nps-button-group {
    position: relative;
    margin: auto;
    padding-left: 2rem;
    padding-right: 2rem;
}

/* Dropdowns */
.dropdown .dropdown-toggle-white {
    background-color: white;
    color: var(--online-dark);
    padding: 6.5px 16px;
    height: 44px;
}

.dropdown .dropdown-toggle-white:hover {
   background-color: var(--online-primary);
   color: white;
}

.dropdown .dropdown-toggle-white::after {
    margin-left: 8px;
}

.dropdown-item.active {
    color: var(--online-dark);
    background-color: transparent;
}

.dropdown-check-item.active::before {
   content: '\2713'; /* Unicode for check mark */
   display: inline-block;
   margin-right: 8px;
   color: var(--online-dark);
   position: absolute;
   left: 8px;
}

.dropdown-check-item {
   padding-left: 24px; /* Padding to align with the check icon */
}

/* Modals */
.modal {
    --bs-modal-width: 644px;
}

.modal-open .modal-backdrop {
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 1 !important;
}

.modal-header {
    padding-bottom: 0;
}

.modal-content .btn-close {
    --bs-btn-close-color: #000;
    --bs-btn-close-bg: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"/></svg>');
    --bs-btn-close-opacity: 0.75;
    --bs-btn-close-hover-opacity: 1;
    --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-btn-close-focus-opacity: 1;
    --bs-btn-close-disabled-opacity: 0.25;
    --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: var(--bs-btn-close-color);
    background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
    border: 0;
    border-radius: 0.375rem;
    opacity: var(--bs-btn-close-opacity);
    position: absolute;
    top: 10px;
    right: 10px;
}

.modal-content {
    padding: 1rem;
    overflow: auto;
}

.modal-content .modal-title {
    font-size: 2rem;
    font-weight: bold;
}

.modal-header .modal-back {
    display: none;
}

.modal-steps {
    position: absolute;
    top: 35px;
    right: 30px;
    font-size: 26px;
    font-weight: bold;
}

.modal-footer {
    padding-top: 0;
    justify-content: flex-start;
}

.modal-content hr {
    width: calc(100% + 4.25em);
    margin-left: -2.25em;
    border-bottom: 6px solid var(--online-lighter);
}

.modal-content .customer-survey {
    width: calc(100% + 4.25em);
    margin-left: -2.25em;
    padding: 1.5rem 2.5rem;
    background-color: var(--online-secondary);
    color: #fff;
    margin-bottom: 1rem;
    cursor: pointer;
    position: relative;
}

.modal-content .customer-survey .survey-pointer {
    position: absolute;
    bottom: 2rem;
    right: 3rem;
}

.modal-content .customer-survey .survey-pointer svg {
    transform: scale(3) rotate(-20deg);
}

.modal-content .customer-survey:hover {
    background-color: var(--online-primary);
}

.modal-content .customer-survey a, .modal-content .customer-survey a:visited {
    color: #fff;
}

.modal-content .customer-survey a:hover {
    color: var(--online-primary);
}

.modal-content .customer-survey p:last-child{
    margin-bottom: 0;
}

.form-modal-parent {
    position: relative;
    margin: 0;
}

.offcanvas-modal.offcanvas {
    position: absolute;
    justify-content: center;
    width: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.95) 30%, rgba(255, 255, 255, 0.1) 95%);
    backdrop-filter: blur(2px);
    opacity: 1 !important;
}

/* Wizard */
.wizard-progress-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 15px;
    border-radius: 0 0 10px 10px;
    background: var(--online-lighter);
}

.wizard-progress {
    height: 100%;
    border-bottom-left-radius: 10px;
    width: 0;
    background: var(--online-primary);
    /* Transitions */
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
}

.wizard-progress-full {
    border-bottom-right-radius: 10px;
}

/* Tooltips */
.tooltip {
    opacity: 1 !important;
}

.tooltip-inner {
    max-width: 300px;
    background: #fff;
    padding: 15px;
    color: rgb(var(--online-text-rgb));
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.tooltip.bs-tooltip-start .tooltip-arrow::before,
.tooltip.bs-tooltip-end .tooltip-arrow::before,
.tooltip.bs-tooltip-bottom .tooltip-arrow::before,
.tooltip.bs-tooltip-top .tooltip-arrow::before {
    border: 0.5em solid #fff;
    transform-origin: 0 0;
    box-shadow: -3px 3px 2px rgba(0, 0, 0, 0.1);
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
    transform: rotate(135deg);
    left: calc(50% + 10px);
    bottom: -15px;
}

.tooltip.bs-tooltip-top .tooltip-arrow::before {
    transform: rotate(-45deg);
}

.tooltip.bs-tooltip-start .tooltip-arrow::before {
    transform: rotate(-135deg);
    bottom: -15px;
}

.tooltip.bs-tooltip-end .tooltip-arrow::before {
    transform: rotate(45deg);
    right: -15px;
}

/* Accordions, lists and list labels */
span.label {
    display: block;
    color: var(--online-light);
    margin-bottom: 7px;
}

.list-unstyled li {
    margin-bottom: 7px;
    border-bottom: 1px solid var(--online-light-list-separator);
}

.serving-modal-unstyled,
.list-unstyled ul {
    padding: 0;
    list-style-type: none;
}

.list-unstyled li:last-child {
    border-bottom: 0;
}

.list-unstyled.vertically-centered-items li p {
    margin-top: 3px;
    margin-bottom: 10px;
}

#payplan-steps {
    padding-left: 0;
    padding-right: 0;
}

#payplan-steps p {
    margin: 0;
}

#payplan-steps .card {
    box-shadow: 0 0 2px #00000030;
    margin-bottom: 10px;
    border-left: 13px solid var(--online-primary);
    border-radius: (--bs-border-radius);
}

#payplan-steps .date {
    font-size: 0.9rem;
}

#payplan-steps .payplan-status {
    font-size: 0.8rem;
    display: block;
    position: absolute;
    top: calc(50% - 0.5rem);
}

#payplan-steps .step.badge {
    background-color: var(--online-primary) !important;
    padding: 5px;
    width: 21px;
    height: 21px;
    position: absolute;
    left: -12px;
    top: calc(50% - 10px);
}

#payplan-steps .badge.bg-success {
    font-size: 0.7rem;
}

.card.paid {
    border-left: 13px solid var(--online-payplan-success) !important;
}

.card.paid .payplan-status {
    color: var(--online-payplan-success);
}

#payplan-steps .paid .badge {
    background: var(--online-payplan-success) !important;

}

.card.is-due {
    border-left: 13px solid var(--online-danger) !important;
}

.card.is-due .payplan-status {
    color: var(--online-danger);
}

#payplan-steps .is-due .badge {
    background: var(--online-danger) !important;

}

button.accordion-button {
    padding: 7px 0 10px;
}

button.accordion-button:not(.collapsed) {
    background-color: var(--bs-white);
}

span.accordion-icon-text {
    padding-top: 5px;
    margin-right: -1rem;
}

span.accordion-inline-button {
    float: right;
    margin-top: -20px;
}

/* Footer */
.footer {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding-left: 300px;
    font-size: 0.8rem;
    padding-top: 1rem;
}

.footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
}

.footer ul li {
    display: inline-block;
    margin: 0;
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: center;
}

.footer button {
    border: 0;
    background: transparent;
}

.footer button:hover {
    color: var(--online-secondary) !important;
    cursor: pointer;
}

.footer button:hover svg {
    color: var(--online-secondary) !important;
}

.footer a, .footer a:visited {
    color: var(--online-light-dark);
    cursor: pointer;
}

.footer a:hover, .footer .active {
    color: var(--online-secondary) !important;
    cursor: pointer;
}

.footer svg {
    color: rgb(var(--online-text-rgb));
    margin-right: 0.5rem;
}

.footer a:hover svg, .footer .active svg {
    color: var(--online-secondary);
}

/* Footer page login styles */
.login-footer {
    text-align: right;
    bottom: 20px;
    right: 10%;
    left: auto;
    transform: none;
    padding-left: 0;
    width: auto;
}

.login-footer button {
    color: var(--online-lighter);
}

.login-footer button:hover {
    color: var(--online-secondary) !important;
    cursor: pointer;
}

.login-footer button:hover svg {
    color: var(--online-secondary) !important;
}

.login-footer a, .login-footer a:visited {
    color: var(--online-lighter);
    cursor: pointer;
}

.login-footer a:hover, .login-footer .active {
    color: var(--online-secondary) !important;
    cursor: pointer;
}

.login-footer svg {
    color: #fff;
    margin-right: 0.5rem;
}

.login-footer a:hover svg, .login-footer .active svg {
    color: var(--online-secondary);
}


/* Popover */
.popover {
    max-width: 350px;
    max-height: 200px;
    padding: 15px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.3);
    text-align: center;
    z-index: 20001; /* on top of sidemenu*/
}

.sidemenu-popover {
    min-width: 350px;
    position: absolute;
    top: -110px;
    left: 20px;
}

.popover::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    bottom: -12px;
    left: calc(50% - 10px);
    box-sizing: border-box;
    border: 0.5em solid black;
    border-color: transparent transparent white white;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.sidemenu-popover::after {
    left: 60px;
}

.popover-arrow {
    display: none !important;
}

.popover-header {
    background-color: transparent;
    font-size: 1.4rem;
    color: rgb(var(--online-text-rgb));
}

/* Css to disable section TODO this should be styled again with new styles */
.section-disabled,
.section-disabled h2 {
    color: var(--online-light);
}

.section-disabled button#subs-button {
    background-color: var(--online-light);
}

/* Responsive styling */
@media only screen and (max-width: 385px) {

    .sidemenu-popover {
        min-width: 300px;
        padding: 10px;
        position: absolute;
        top: -110px;
        left: 20px;
    }
}

@media only screen and (max-width: 575.98px) {

    #dashboard {
        padding-bottom: 80px !important;
    }

    #job-page {
        padding-bottom: 70px;
    }

    .footer {
        bottom: -60px;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .login-footer {
        bottom: 0;
        right: 0;
    }

    /* Mobile modal */
    .modal-dialog {
        margin: 0;
    }

    .modal-content {
        height: 100vh;
        border-radius: 0;
        padding: 0.5rem;
    }

    .modal-dialog .btn-close {
        display: none;
    }

    .modal-header {
        display: block;
    }

    .modal-header .modal-back {
        font-size: 1rem;
        background: transparent;
        color: var(--online-text-rgb);
        font-weight: bold;
        display: block;
        padding: 0;
        margin: 0;
    }

    .modal-steps {
        top: 15px;
        right: 15px;
    }

    .offcanvas-modal.offcanvas {
        justify-content: inherit;
        background: rgba(255, 255, 255,1);
    }

    .modal-content.mobile-message-content {
        padding-bottom: 0;
    }

    /* Mobile wizard */
    .wizard-progress-container,
    .wizard-progress,
    .wizard-progress-full {
        border-radius: 0;
    }

    .card.invoice-details.paid {
        border-bottom: 8px solid var(--online-success);
    }

    .card.invoice-details.invoicing {
        border-bottom: 8px solid var(--online-secondary);
    }

    .card.invoice-details.late {
        border-bottom: 8px solid var(--online-warning);
    }

    .card.invoice-details.reminder {
        border-bottom: 8px solid var(--online-danger);
    }

    .card.invoice-details.collection {
        border-bottom: 8px solid var(--online-dark);
    }

    .buttons-section-mobile,
    .card.invoice-details.card.invoice-details.no-rounding-bottom {
        border-radius: 20px;
        width: calc(100% - 2.5rem);
        max-width: calc(100% - 2.5rem);
        margin-left: calc(+1rem);
        margin-right: calc(+1rem);
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
    }

    .buttons-section-mobile,
    .card.fullwidth-mobile {
        border-radius: 0 !important;
        box-shadow: none;
        width: calc(100% + 1.5rem);
        max-width: calc(100% + 1.5rem);
        margin-left: calc(-0.75rem);
        margin-right: calc(-0.75rem);
    }

    .card.fullwidth-mobile.messagecard {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .card.messagecard.chosen {
        background-color: var(--online-lighter);
        border: none;
    }

    .card.messagecontent {
        box-shadow: none;
    }

    .grey-bottom-border-mobile {
        border-bottom: solid 7px #f5f5f5 !important;
    }

    .retrieve-invoice .online-tabs .nav-item a {
        font-size: 0.9rem;
    }

    .profilemenu-open {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 1) 60%);
    }

    .long-floating-label {
        white-space: normal !important;
    }

    body {
        background-color: white;
    }

    .has-search {
        box-shadow: 0px 1px 4px rgba(0,0,0,0.25);
        border-radius: 8px;
    }

    .btn.messagecard {
        border-radius: 0;
    }
    
    .btn.messagecard:hover {
        background-color: var(--online-lighter);
    }

    .scrollable-content,
    .message-container {
        border-radius: 0px;
    } 

    .scrollable-content-message {
        overflow-y: inherit;
        height: 95%;
    }

    .fixed-element-bottom {
        position: sticky;
        bottom: 0px;
        background-color: white;
    }

    .fixed-element-top {
        position: sticky;
        top: -8px;
        background-color: white;
        z-index: 100;
    }

    /*
    * Bit of CSS magic to get full width tabs inside container
    */
    .online-tabs {
        width: calc(100% + 1.5rem);
        max-width: calc(100% + 1.5rem);
        margin-left: calc(-0.75rem);
        margin-right: calc(-0.75rem);
    }
}

@media only screen and (max-width: 767.98px) and (min-width: 576px) {

    #dashboard {
        padding-bottom: 120px !important;
    }

    #job-page {
        padding-bottom: 120px;
    }

    .card.invoice-details.job-shortsummary {
        box-shadow: 0px 1px 2px rgba(0,0,0,0.15);
    }
}

@media only screen and (max-width: 767.98px) {

    .card.invoice-details.rounding-top-mobile {
        border-radius: 20px;
    }

    .margin-top-mobile {
        margin-top: 10px;
    }
}

@media only screen and (max-width: 991.98px) {
    .extend-duedate {
        clear: both;
        float: right;
        width: 165px;
    }
    
    /* Message center modal for smaller screen sizes */
    .modal-dialog.mobile-message-content {
        margin: 0;
        max-width: 100%;
    }

    .modal-content.mobile-message-content {
        height: 100vh;
        border-radius: 0;
        padding: 0.5rem;
    }

    .modal-header.mobile-message-content {
        display: block;
    }

    .modal-header.mobile-message-content .modal-back {
        font-size: 1rem;
        background: transparent;
        color: var(--online-text-rgb);
        font-weight: bold;
        display: block;
        padding: 0;
        margin: 0;
    }

    .card.messagecontent {
        box-shadow: none;
    }
}

@media only screen and (max-width: 1199.98px) {

    #dashboard {
        margin-top: 3rem;
    }

    .welcome-message {
        margin-top: 0px;
    }

    .footer {
        padding-left: 0;
    }

    .footer ul li {
        margin-top: 1rem;
    }

    .login {
        background-size: auto;
    }

    .login-header {
        height: 20%;
    }

    .top-menu-position {
        left: auto;
        width: 100%;
    }

    .top-menu  {
        padding-right: 25px;
    }

    .profile span.option-icon,
    .profilemenu {
        top: 5px;
    }

    .profilemenu-header {
        padding-top: 15px;
    }

    h1 {
        font-size: 2.2rem;
    }

    .card {
        box-shadow: 0px 1px 2px rgba(0,0,0,0.15);
    }

    .card.messagecard {
        box-shadow: none;
    }

    .card h3 {
        font-size: 1.3rem;
    }

    .online-tabs .nav-item a {
        font-size: 1.4rem;
    }

    span.accordion-icon-text {
        margin-right: 0;
    }

    .browse {
        position: absolute;
        top: -42px;
    }

    #main-container {
        min-height: calc(100vh - 2rem);
    }

    #sidemenu {
        min-height: calc(100vh + 2rem);
        z-index: 10000;
    }

    .icon-need-actions {
        border: 2px solid var(--online-danger);
    }
}

/* hover interactions for devices that support it*/
@media (hover: hover) {
    .feedback-btn-circle:hover {
        background-color: var(--online-primary);
        color: #FFF;
    }
}